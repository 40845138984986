import React, { Component } from 'react';
import Button from 'reactstrap-button-loader';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    Container,
    Row,
    Col
} from 'reactstrap';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { confirmOrder } from '../actions/itemActions';
import { NotificationManager } from 'react-notifications';

const ORDER_STATUS_PENDING = require('./constants').ORDER_STATUS_PENDING;

class ConfirmOrderModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: false,
            preparation_time: this.props.item.order.delivery_tasks[0] && this.props.item.order.delivery_tasks[0].order_type==="pre-order" ? 
            "-1" : this.props.globalState.settings.preparation_time,
            message: ""
        }
    }

    callback = success => {        
        this.setState({ loading: false });
        this.toggle();
        
        if(success){
            const item = this.props.item;
            const isPickup = (item && item.order && item.order.delivery_tasks[0] && item.order.delivery_tasks[0].method==="pickup");
            let msg = "You've accepted the order. ";
            msg += (isPickup ? "Please fulfill the order once it's been collected." : "We'll dispatch a driver soon to collect it.");
            NotificationManager.success(msg);
        } else {
            NotificationManager.error('There was a problem accepting the order. It may have already been accepted.');
        }
    }    

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
            preparation_time: (this.props.item.order.delivery_tasks[0] && this.props.item.order.delivery_tasks[0].order_type==="pre-order") ? 
            "-1" : this.props.globalState.settings.preparation_time,
            message: ""
        });
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit = e => {
        e.preventDefault();
        this.setState({ 
            loading: true
        });
        
        if(this.props.item.accepted){
            this.callback("error");
        } else{
            let confirmObj = {}; 
            confirmObj.item_id = this.props.item._id;
            confirmObj.order_id = this.props.item.order.id;
            confirmObj.preparation_time = this.state.preparation_time;
            confirmObj.accepted = new Date();
            if(this.state.message){
                confirmObj.message = {
                    text: this.state.message,
                    sent: new Date(),
                    sender: this.props.globalState.settings.restaurant_name,
                    recipient: "Hopscotch"
                };
            }
            
            this.props.confirmOrder(confirmObj, this.callback);
        }
    }

    onButtonClick = (e) => {
        this.setState({ preparation_time: e.target.value });
    }

    render(){
        const { item } = this.props;
        const delivery_task = item.order.delivery_tasks[0];
        const isPreorder = (delivery_task && delivery_task.order_type === "pre-order");
        let isRange, isPickup;

        if(isPreorder){
            isRange = (delivery_task.pre_order_time === "range");
            isPickup = (delivery_task.method === "pickup")
        }
        return (
            <>
                <Button className="btn-hopscotch" style={{width: '100%'}}
                        disabled={
                                    !!item.deleted || 
                                    !!item.accepted || 
                                    !!item.cancelled || 
                                    !!item.cancelled_after_collection ||
                                    !!item.rejected ||
                                    item.order.state !== ORDER_STATUS_PENDING
                                }
                        onClick={this.toggle}>
                            Confirm
                </Button>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader toggle={this.toggle}>Confirm Order</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>
                            {!isPreorder ? (
                                <>
                                    <Label>How long to prepare the food?</Label>
                                    <Container>
                                        <Row>
                                            <Col><Button className="btn-hopscotch" value="5" active={this.state.preparation_time === "5"} outline onClick={this.onButtonClick} style={{marginBottom: '1rem', width: '100%'}}>5 mins</Button></Col>
                                            <Col><Button className="btn-hopscotch" value="10" active={this.state.preparation_time === "10"} outline onClick={this.onButtonClick} style={{marginBottom: '1rem', width: '100%'}}>10 mins</Button></Col>
                                            <Col><Button className="btn-hopscotch" value="15" active={this.state.preparation_time === "15"} outline onClick={this.onButtonClick} style={{marginBottom: '1rem', width: '100%'}}>15 mins</Button></Col>
                                        </Row>
                                        <Row>
                                            <Col><Button className="btn-hopscotch" value="20" active={this.state.preparation_time === "20"} outline onClick={this.onButtonClick} style={{marginBottom: '1rem', width: '100%'}}>20 mins</Button></Col>
                                            <Col><Button className="btn-hopscotch" value="25" active={this.state.preparation_time === "25"} outline onClick={this.onButtonClick} style={{marginBottom: '1rem', width: '100%'}}>25 mins</Button></Col>
                                            <Col><Button className="btn-hopscotch" value="30" active={this.state.preparation_time === "30"} outline onClick={this.onButtonClick} style={{marginBottom: '1rem', width: '100%'}}>30 mins</Button></Col>
                                        </Row>
                                        <Row>
                                            <Col><Button className="btn-hopscotch" value="45" active={this.state.preparation_time === "45"} outline onClick={this.onButtonClick} style={{marginBottom: '1rem', width: '100%'}}>45 mins</Button></Col>
                                            <Col><Button className="btn-hopscotch" value="60" active={this.state.preparation_time === "60"} outline onClick={this.onButtonClick} style={{marginBottom: '1rem', width: '100%'}}>1 hour</Button></Col>
                                            <Col><Button className="btn-hopscotch" value="90" active={this.state.preparation_time === "90"} outline onClick={this.onButtonClick} style={{marginBottom: '1rem', width: '100%'}}>1.5 hours</Button></Col>
                                        </Row>
                                        <Row>
                                            <Col><Button className="btn-hopscotch" value="120" active={this.state.preparation_time === "120"} outline onClick={this.onButtonClick} style={{marginBottom: '1rem', width: '100%'}}>2 hours</Button></Col>
                                            <Col><Button className="btn-hopscotch" value="180" active={this.state.preparation_time === "180"} outline onClick={this.onButtonClick} style={{marginBottom: '1rem', width: '100%'}}>3 hours</Button></Col>
                                            <Col><Button className="btn-hopscotch" value="-1" active={this.state.preparation_time === "-1"} outline onClick={this.onButtonClick} style={{marginBottom: '1rem', width: '100%'}}>Pre-order</Button></Col>
                                        </Row>
                                    </Container>
                                </>
                                ) : 
                                <>
                                    <span style={{ color: "red"}}>{isPickup ? "Collection at: " : "Delivery for: "}<Moment 
                                    format={isRange ? "dddd, MMMM Do" : "dddd, MMMM Do, h:mma"} 
                                    date={item.order.delivery_tasks[0] ? new Date(item.order.delivery_tasks[0].delivery_time_to) : null} local /></span>
                                    <p/>
                                    {!isPickup && !isRange?<p>Hopscotch will aim to collect 20-60 mins before this time, depending on distance.</p>:''}
                                    <p/>
                                </>
                                }

                                <Input 
                                    type="textarea" 
                                    name="message" 
                                    id="message" 
                                    placeholder="Optional message for Hopscotch"
                                    value={this.state.message}
                                    onChange={this.onChange}
                                />

                                <Button 
                                    loading={this.state.loading} 
                                    className="btn-hopscotch" 
                                    style={{ marginTop: '2rem'}} 
                                    disabled={
                                        !!item.deleted || 
                                        !!item.accepted || 
                                        !!item.cancelled || 
                                        !!item.cancelled_after_collection ||
                                        !!item.rejected ||
                                        item.order.state !== ORDER_STATUS_PENDING
                                    }   
                                    block>
                                    Confirm Order
                                </Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    globalState: state.item
});
export default connect(mapStateToProps, { confirmOrder })(ConfirmOrderModal);