import { 
    ADD_ITEMS,
    REMOVE_ITEM,
    UPDATE_ITEMS,
    GET_ITEMS,  
    ITEMS_LOADING,  
    SAVE_SETTINGS,
    NO_ITEMS
 } from '../actions/types'; 

const moment = require('moment');
const initialState = {
    items: [],
    loading: false,
    settings: JSON.parse(localStorage.getItem('settings')) || {} 
};

const TASK_STATUS_SUCCESSFUL = 2;
const ORDER_STATUS_REJECTED = 3;
const ORDER_STATUS_FULFILLED = 4;
const ORDER_STATUS_CANCELLED = 5;
const ORDER_STATUS_CANCELLED_AFTER_COLLECTION = 7;

export default function (state = initialState, action){
    const now = moment(new Date());
    const start_of_today = now.startOf("day").toDate();
    const start_of_past_period = now.clone().subtract(7, 'days').startOf("day").toDate();

    switch(action.type) {
        case GET_ITEMS: 
            return {
                ...state,
                items: action.payload,
                loading: false
            };
        
        case ADD_ITEMS: 
            return {
                ...state,
                items: filterFunc([...action.payload, ...state.items]).sort(sortFunc),
                loading: false
            };

        case UPDATE_ITEMS: 
            return {
                ...state,
                items: state.items.map(item => action.payload.find(i => i._id === item._id) || item)
                                .filter(item => new Date(item.order.order_date) >= start_of_today || 
                                    (new Date(item.order.order_date) >= start_of_past_period && new Date(item.order.order_date) < start_of_today && !isItemComplete(item)) ||
                                    item.messages.some(m => m.sender === "Hopscotch" && !m.readByRestaurant)                              
                                ),
                loading: false
            };
        
        case REMOVE_ITEM: 
            return {
                ...state,
                items: state.items.filter(item => item._id !== action.payload),
                loading: false
            };
        
        case ITEMS_LOADING:
            return {
                ...state,
                loading: true
            };
        case SAVE_SETTINGS:
            return {
                ...state,
                settings: action.payload
            };
        case NO_ITEMS:
            return {
                ...state,
                items: [],
                loading: false
            };
        
        default: 
            return state;
    }
}

// Filter out duplicate items
function filterFunc(arr){
    const itemIds = arr.map(i => i._id);
    return arr.filter((i, index) => itemIds.indexOf(i._id) === index);
}
function sortFunc(a, b){
    if (new Date(a.order.order_date) > new Date(b.order.order_date)) {
        return -1;
      }
      if (new Date(a.order.order_date) < new Date(b.order.order_date)) {
        return 1;
      }
      return 0;
}

function isItemComplete(item){
    if(!item) return false;

    return (
        (item.delivery_task && item.delivery_task.job_status===TASK_STATUS_SUCCESSFUL) ||
        (item.order && [
            ORDER_STATUS_REJECTED, 
            ORDER_STATUS_FULFILLED, 
            ORDER_STATUS_CANCELLED, 
            ORDER_STATUS_CANCELLED_AFTER_COLLECTION
        ].includes(item.order.state)) ||
        item.cancelled ||
        item.cancelled_after_collection ||
        item.rejected ||
        item.pickup_completed
    );
}