import BootstrapTable from 'react-bootstrap-table-next';
import React, { Component } from 'react';
import { Badge } from 'reactstrap';
import ItemDetails from './ItemDetails';
import { connect } from 'react-redux';
import {  viewItem, setLastJinglePause } from '../actions/itemActions';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
//import paginationFactory from 'react-bootstrap-table2-paginator';
import 'moment-timezone';
import audio from '../static/hopscotch_audio.mp3';

const constants = require('./constants');

let jingle = new Audio(audio);
jingle.loop = true;

let itemStatusBadgeIntervals = {};
let newMessageBadgeIntervals = {};
let lateConfirmationJingleTimeouts = {};

const ORDER_STATUS_BEING_AMENDED = constants.ORDER_STATUS_BEING_AMENDED;
const ORDER_STATUS_PENDING = constants.ORDER_STATUS_PENDING;
const ORDER_STATUS_REJECTED = constants.ORDER_STATUS_REJECTED;
const ORDER_STATUS_CANCELLED = constants.ORDER_STATUS_CANCELLED;
const ORDER_STATUS_IN_PROGRESS = constants.ORDER_STATUS_IN_PROGRESS;
const ORDER_STATUS_SUCCESSFUL = constants.ORDER_STATUS_SUCCESSFUL;

const CONFIRMATION_TIME_IN_SECONDS = constants.CONFIRMATION_TIME_IN_SECONDS;
const TASK_STATUS_STARTED = constants.TASK_STATUS_STARTED;
const TASK_STATUS_SUCCESSFUL = constants.TASK_STATUS_SUCCESSFUL;
const TASK_STATUS_ARRIVED = constants.TASK_STATUS_ARRIVED;
const TASK_STATUS_CANCELLED = constants.TASK_STATUS_CANCELLED;

class ItemList extends Component { 
    componentDidUpdate(prevProps){ 
        let playJingle = false;
        let now = new Date();
        let jingleForNewMessage, jingleForUnreadItem, jingleForLateConfirmation;
        for(const item of this.props.globalState.items){
            if(this.jinglePlaying(item, now)){
                playJingle = true;
            }
            
            jingleForUnreadItem = this.jinglePlayingForUnreadItem(item);
            jingleForLateConfirmation = this.jinglePlayingForLateConfirmation(item, now); 
            
            if(jingleForUnreadItem || 
               jingleForLateConfirmation){
                if(!itemStatusBadgeIntervals["ID"+item._id]){
                    itemStatusBadgeIntervals["ID"+item._id] = setInterval(() => 
                                            this.toggleBadge("ItemStatusBadge"+item._id), 1000);
                }
            } else {
                if(itemStatusBadgeIntervals["ID"+item._id]){
                    clearInterval(itemStatusBadgeIntervals["ID"+item._id]);
                    delete itemStatusBadgeIntervals["ID"+item._id];
                }
                let itemStatusBadge = document.getElementById(["ItemStatusBadge"+item._id]);
                if(itemStatusBadge) itemStatusBadge.style.display = 'inline';
            }
            
            jingleForNewMessage = this.jinglePlayingForNewMessage(item);
            if(jingleForNewMessage){
                if(!newMessageBadgeIntervals["ID"+item._id]){
                    newMessageBadgeIntervals["ID"+item._id] = setInterval(() => 
                                            this.toggleBadge("NewMessageBadge"+item._id), 1000);
                }
            } else {
                if(newMessageBadgeIntervals["ID"+item._id]){
                    clearInterval(newMessageBadgeIntervals["ID"+item._id]);
                    delete newMessageBadgeIntervals["ID"+item._id];
                }
                let newMessageBadge = document.getElementById(["NewMessageBadge"+item._id]);
                if(newMessageBadge) newMessageBadge.style.display = 'none';
            }           

            if(item && !item.deleted && !item.accepted && !item.problem && item.order.state === ORDER_STATUS_PENDING && 
                item.last_jingle_pause && !jingleForLateConfirmation && 
                !lateConfirmationJingleTimeouts["ID"+item._id]){
                    lateConfirmationJingleTimeouts["ID"+item._id] = setTimeout(() => 
                    this.lateConfirmationTimeoutFunction(item._id), Math.max(0, CONFIRMATION_TIME_IN_SECONDS * 1000 - 
                       (now.getTime()-new Date(item.last_jingle_pause).getTime())));
                } 
        }

        if(playJingle) { 
            this.playJingle();
        } else {
            jingle.pause();
        }            
    }

    componentWillUnmount() {
        for(const item of this.props.globalState.items){
            if(itemStatusBadgeIntervals["ID"+item._id]){
                clearInterval(itemStatusBadgeIntervals["ID"+item._id]);
                delete itemStatusBadgeIntervals["ID"+item._id];
            }

            if(newMessageBadgeIntervals["ID"+item._id]){
                clearInterval(newMessageBadgeIntervals["ID"+item._id]);
                delete newMessageBadgeIntervals["ID"+item._id];
            }
        }
        
      }
    
    lateConfirmationTimeoutFunction = itemId => {
        let now = new Date();
        let item = this.props.globalState.items.find(i => i._id === itemId);
        if(this.jinglePlayingForLateConfirmation(item, now)){
            jingle.currentTime = 0;
            this.playJingle();
            itemStatusBadgeIntervals["ID"+itemId] = setInterval(() => 
                this.toggleBadge("ItemStatusBadge"+itemId), 1000);
            delete lateConfirmationJingleTimeouts["ID"+itemId];
           } else {
            if(item && !item.deleted && !item.accepted && !item.problem && item.order.state === ORDER_STATUS_PENDING && 
                item.last_jingle_pause){
                    lateConfirmationJingleTimeouts["ID"+itemId] = setTimeout(() => 
                    this.lateConfirmationTimeoutFunction(itemId), 
                    Math.max(0, CONFIRMATION_TIME_IN_SECONDS * 1000 - 
                       (now.getTime()-new Date(item.last_jingle_pause).getTime())));
            } 
        }
    }

    onView = i => {
        const id = i._id;
        const now = new Date();
        const jinglePlaying = this.jinglePlaying(i, now);
        const jingleForUnreadItem = this.jinglePlayingForUnreadItem(i);
        const jingleForLateConfirmation = this.jinglePlayingForLateConfirmation(i, now);
  
        if(jinglePlaying) {
            if(jingleForUnreadItem){
                this.props.viewItem(id, now);
            } else if (jingleForLateConfirmation) {
                // Note: Set the last jingle pause time even if we don't actually pause the jingle.
                // The jingle may be playing for other items, but we need to reset the jingle clock on this item.
                // Don't set the last jingle pause when the jingle is playing for a new message.
                this.props.setLastJinglePause(id, now);
            }
        }

    }

    jinglePlaying = (item, now) => {
        return this.jinglePlayingForUnreadItem(item) ||
               this.jinglePlayingForLateConfirmation(item, now) || 
               this.jinglePlayingForNewMessage(item);
    }

    jinglePlayingForUnreadItem = item => {        
        return (!!item && item.order.state === ORDER_STATUS_PENDING && !item.read && 
                !item.deleted && !item.cancelled && !item.cancelled_after_collection
                && !item.rejected);
    }

    jinglePlayingForLateConfirmation = (item, now) => {        
        return (!!item && item.order.state === ORDER_STATUS_PENDING && item.read && !item.problem && 
               !item.accepted && !item.deleted && !item.cancelled && !item.cancelled_after_collection
               && !item.rejected && !!item.last_jingle_pause && 
                (now.getTime() - new Date(item.last_jingle_pause).getTime()) / 1000 > CONFIRMATION_TIME_IN_SECONDS
        );
    }

    jinglePlayingForNewMessage = item => {  
        return (
                    !!item && !item.deleted && item.messages.length > 0 
                    && !!item.messages.find(m => m.sender === "Hopscotch" && !m.readByRestaurant)
                );
    }

    playJingle = () => {
        try{
            let playPromise = jingle.play();
            if (playPromise !== null){
                playPromise.catch(() => jingle.play() );
            }
        }catch(err){
            console.log(`Caught error playing jingle: ${err}. Trying again...`);
            jingle.play();
        }
    }

    itemLateInConfirmation = (item, now) => {        
        return (!!item && item.order.state === ORDER_STATUS_PENDING && item.read && !item.problem && !item.accepted
                && !item.deleted && 
                (now.getTime() - new Date(item.read).getTime()) / 1000 > CONFIRMATION_TIME_IN_SECONDS
        );
    }
    
    toggleBadge = (elemID) => {
        let badge = document.getElementById(elemID);
        if(badge){
            badge.style.display = badge.style.display === 'inline' ? 'none' : 'inline';
        }
    }

    nameFormatter = (cell, row) => {
        return (
            <span>{row.order.first_name + ' ' + row.order.last_name}</span>
        );
      }

    dateFormatter = cell => {
        return cell ? <Moment format="h:mma" date={new Date(cell)} local /> : "";
    }

    datetimeFormatter = cell => {
        if(!cell) return "";

        let datetime = new Date(cell);
        if(this.isToday(datetime)){
            return <Moment format="h:mma" date={datetime} local />;
        } else {
            //return <Moment format="h:mma" date={datetime} local />;
            return <Moment format="MMM D, h:mma" date={datetime} local />
        }        
    }

    isToday(someDate){
        let today = new Date(); 
        return today.getFullYear() === someDate.getFullYear() 
        && today.getMonth() === someDate.getMonth()
        && today.getDate() === someDate.getDate();
    }

    orderStateFormatter = (cell, item) => {
        let text, colour;
        if(!item) return "";

        let id = "ItemStatusBadge"+item._id;

        if((item.pickup_task && item.pickup_task.job_status === TASK_STATUS_CANCELLED) || 
            item.order.state === ORDER_STATUS_REJECTED || 
            item.order.state === ORDER_STATUS_CANCELLED ||
            item.deleted || item.cancelled || item.cancelled_after_collection ||
            item.rejected){
                colour = "danger";
                text = "Cancelled";
        } else if (item.order.state === ORDER_STATUS_PENDING && !item.accepted){
            colour = "danger";
            if(item.problem){
                text = "Problem";
            } else if(item.amended){
                text = "Amended";
            } else {
                text = "Pending";
            }
        } else if (item.order.state === ORDER_STATUS_BEING_AMENDED) {
            colour = "secondary";
            text = "Amending";
        } else if ((item.pickup_task && item.pickup_task.job_status === TASK_STATUS_SUCCESSFUL) || 
                   item.order.state === ORDER_STATUS_SUCCESSFUL || item.pickup_completed){
            colour = "success";
            text = "Collected";
        } else if(!item.pickup_task || 
            ![TASK_STATUS_STARTED, 
              TASK_STATUS_ARRIVED, 
              TASK_STATUS_SUCCESSFUL, 
              TASK_STATUS_CANCELLED]
              .includes(item.pickup_task.job_status)
              ){
                colour = "secondary";
                text = "Confirmed";
        } else if (item.pickup_task.job_status === TASK_STATUS_STARTED){
            colour = "secondary";
            text = "Driver en route";
        } else if (item.pickup_task.job_status === TASK_STATUS_ARRIVED){
            colour = "secondary";
            text = "Driver arrived";
        } 
        return <Badge id={id} color={colour} pill>{text}</Badge>;
    }

    arrivalTimeFormatter = (cell, item) => {

        let pickup_started;
        
        if(item && item.pickup_task && item.pickup_task.started_datetime) {
            pickup_started = new Date(item.pickup_task.started_datetime); 
        }
       
        return (
            <span>{item && item.pickup_task && item.pickup_task.started_datetime && item.distance_to_restaurant ? 
                <Moment format={this.isToday(pickup_started) ? "h:mma" : "MMM D, h:mma"} 
                add={
                    {
                        "hours": item.distance_to_restaurant / 28968,
                        "minutes": 5 
                    }
                } local>{new Date(item.pickup_task.started_datetime)}</Moment>
                    :
                    ""
                }</span>
        );
    }

    collectionTimeFormatter = (cell, item) => {

        let datetime;
        if(item && item.pickup_completed){
            datetime = new Date(item.pickup_completed);
        } else if(item && item.pickup_task && item.pickup_task.completed_datetime){
            datetime = new Date(item.pickup_task.completed_datetime);
        } else if (item && item.order && item.order.fulfill_date){
            // For pickup orders
            datetime = new Date(item.order.fulfill_date);
        } else {
            return "";
        }

        if(this.isToday(datetime)){
            return <Moment format="h:mma" date={datetime} local />;
        } else {
            return <Moment format="MMM D, h:mma" date={datetime} local />
        }    
    }

    readyByFormatter = (cell, item) => {

        if(item && item.order && item.order.delivery_tasks[0] && item.order.delivery_tasks[0].delivery_time_to){
            
            return (<Moment 
                        format={item.order.delivery_tasks[0].pre_order_time==='range' ? "MMM D" : "MMM D, h:mma"} 
                        date={new Date(item.order.delivery_tasks[0].delivery_time_to)} local />);
        }
       
        let accept_date;
        if(item && (item.accepted || item.order.accept_date) ){
            accept_date = new Date(item.accepted || item.order.accept_date); 
        }
        return (
            <span>{item && item.preparation_time && 
                    (item.accepted || item.order.state === ORDER_STATUS_IN_PROGRESS)? 
                (item.preparation_time > 0 ? 
                <Moment format={this.isToday(accept_date) ? "h:mma" : "MMM D, h:mma"} 
                add={
                    {
                        "minutes": item.preparation_time 
                    }
                } local>{accept_date}</Moment>
                    :
                    "Pre-order")
                : ""
                }</span>
        );
    }

    messagesFormatter = (cell, item) => {
        return <Badge id={"NewMessageBadge"+item._id} color="danger" style={{ display: 'none'}} pill>New</Badge>;
    }

    render(){
        const { items } = this.props.globalState;
        const columns = [
            {
                dataField: 'order.order_date',
                text: 'Time',
                formatter: this.datetimeFormatter,
                //headerStyle: { textAlign: 'center'},
                //align: 'center'
            }, 
            {
                dataField: 'order.first_name',
                text: 'Customer',
                formatter: this.nameFormatter,
                //headerStyle: { textAlign: 'center'},
            },
            {
                dataField: 'order.state',
                text: 'Status',
                formatter: this.orderStateFormatter,
                //headerStyle: {  textAlign: 'center'},
                //align: 'center'
            },
            {
                dataField: 'Ready For',
                text: 'Ready For',
                formatter: this.readyByFormatter,
                isDummyField: true
                //headerStyle: {  textAlign: 'center'},
                //align: 'center'
            },
            {
                dataField: 'estimated_arrival',
                text: 'Est. Arrival',
                formatter: this.arrivalTimeFormatter,
                //headerStyle: { textAlign: 'center'},
                //align: 'center',
                isDummyField: true
            },
            {
                dataField: 'pickup_task.arrived_datetime',
                text: 'Arrived',
                formatter: this.datetimeFormatter,
                //headerStyle: { textAlign: 'center'},
                //align: 'center'
            },
            {
                dataField: 'Collected',
                text: 'Collected',
                formatter: this.collectionTimeFormatter,
                //headerStyle: {  textAlign: 'center'},
                //align: 'center'
                isDummyField: true
            },
            {
                dataField: 'messages',
                text: 'Messages',
                formatter: this.messagesFormatter,
                //headerStyle: { textAlign: 'center'},
                //align: 'center',
                isDummyField: true
            }
        ];

        const expandRow = {
            renderer: item => <ItemDetails item={item} />
        };

        const rowEvents = {
            onClick: (e, item) => {
                this.onView(item);
            }
          };

        const rowClasses = (row, rowIndex) => {
            if(row && row.order){
                setTimeout(() => {
                    let selectedRow = document.querySelector(`#data_table tbody tr.item${row._id}`);
                    if(selectedRow && !selectedRow.className.includes("fade-enter-active")){
                        selectedRow.className+=" fade-enter-active";
                    }
                    
                }, 1000);
                return `item${row._id} fade-enter fade-enter-active`;
            } else {
                return "";
            }
          };
          
        
        /*const pageButtonRenderer = ({
            page,
            active,
            disable,
            title,
            onPageChange
          }) => {
            const handleClick = (e) => {
              e.preventDefault();
              onPageChange(page);
            };
            const activeStyle = {
                padding: '0.5rem 0.75rem',
                borderRadius: '0.25rem',
                textDecoration: 'none'
            };
            if (active) {
              activeStyle.backgroundColor = '#a32273';
              activeStyle.color = 'white';
            } else {
              activeStyle.backgroundColor = '#8f1061';
              activeStyle.color = 'black';
            }
            if (typeof page === 'string') {
              activeStyle.backgroundColor = 'white';
              activeStyle.color = '#a32273';
            }
            return (
              <li className="page-item">
                <a href="#" onClick={ handleClick } style={ activeStyle }>{ page }</a>
              </li>
            );
          };*/
        
        return (      
            <BootstrapTable 
                bootstrap4
                keyField="_id" 
                id="data_table"
                data={ items } 
                columns={ columns } 
                expandRow={ expandRow }
                rowEvents={ rowEvents }
                bordered={ false }
                rowClasses={ rowClasses }
                noDataIndication="No orders yet for the day."
                /*pagination={ 
                    paginationFactory({
                        //pageButtonRenderer
                        //hideSizePerPage: true
                    }) 
                }*/
                striped
                hover
                condensed
            />
        );
    }

}

ItemList.propTypes = {
    globalState: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    globalState: state.item
});
export default connect(mapStateToProps, { 
    viewItem, 
    setLastJinglePause
} )(ItemList);

