import axios from 'axios';
import { 
    ADD_ITEMS,
    REMOVE_ITEM,
    UPDATE_ITEMS,
    GET_ITEMS,  
    ITEMS_LOADING, 
    SAVE_SETTINGS,
    NO_ITEMS
} from './types';

export const confirmOrder = (confirmObj, callbackSuccess) => dispatch => {               
    
    axios
    .put(`/api/items/${confirmObj.item_id}`, {
        accepted: confirmObj.accepted,
        preparation_time: confirmObj.preparation_time,
        message: confirmObj.message 
    })
    .then(() => callbackSuccess(true))
    .catch(err => {
        console.log(err);
        callbackSuccess(false);
    });
};

export const markProblemOrder = (problemObj, callbackSuccess) => dispatch => {
    
    axios
        .put(`/api/items/${problemObj.item_id}`, {
            problem: problemObj.problem_date,
            message: problemObj.message
        })
        .then(() => callbackSuccess(true))
        .catch(err => {
            console.log(err);
            callbackSuccess(false);
        });
};

export const completePickup = (item, callbackSuccess) => dispatch => {
    
    axios
        .put(`/api/items/${item.item_id}`, {
            pickup_completed: item.pickup_completed,
            final_order_total: item.final_order_total
        })
        .then(() => callbackSuccess(true))
        .catch(err => {
            console.log(err);
            callbackSuccess(false);
        });
};

export const sendMessage = (item, callbackSuccess) => dispatch => {
    axios
        .put(`/api/items/${item.id}`, {
            message: item.message 
        })
        .then(() => callbackSuccess("send", true))
        .catch(err => {
            console.log(err);
            callbackSuccess("send", false);
        });
};

export const readMessages = (item, callbackSuccess) => dispatch => {
    axios
        .put(`/api/items/${item.id}`, {
            "messages.readByRestaurant": item.readByRestaurant 
        })
        .then(() => callbackSuccess("read", true))
        .catch(err => {
            console.log(err)
            callbackSuccess("read", false);
        });
};

export const getItems = () => dispatch => {
    const settings = JSON.parse(localStorage.getItem('settings'));
    if(settings && settings.restaurant_name){
        dispatch(setItemsLoading());
        // Make sure that the Restaurant ID is valid before fetching items. Otherwise, 
        // people who have left the restaurant might still be able to see orders in the RFA

        axios
        .post('/api/settings', {
            identifier: settings.restaurant_id
        }).then(res => 
            axios
            .get('/api/items/restaurant', {
                params: {
                    restaurant_name: JSON.parse(localStorage.getItem("settings")).restaurant_name,
                }
            })
            .then(res => {
                dispatch({
                    type: GET_ITEMS,
                    payload: res.data
                });
            }
            ).catch(err => console.log(err))
        ).catch(err => {
            console.log(err);
            dispatch({
                type: NO_ITEMS
            });
        });

        /*axios
            .get('/api/items/restaurant', {
                params: {
                    restaurant_name: JSON.parse(localStorage.getItem("settings")).restaurant_name,
                }
            })
            .then(res => {
                dispatch({
                    type: GET_ITEMS,
                    payload: res.data
                });
            }
            ).catch(err => console.log(err));*/
    }
};

export const addItems = items => {
    return {
        type: ADD_ITEMS,
        payload: items
    };
};

export const updateItems = items => {
    return {
        type: UPDATE_ITEMS,
        payload: items
    };
};

export const removeItem = id => {
    return {
        type: REMOVE_ITEM,
        payload: id
    };
};

export const saveSettings = (settings, callbackSuccess) => dispatch => {
    if(settings.restaurant_id){
        axios
        .post('/api/settings', {
            identifier: settings.restaurant_id
        }).then(res => {
            const savedSettings = JSON.parse(localStorage.getItem('settings')) || {};
            savedSettings.restaurant_name = res.data.tablet;
            savedSettings.restaurant_id = settings.restaurant_id;
            savedSettings.preparation_time = settings.preparation_time;
            savedSettings.pubnub_publish_key = res.data.pubnub_publish_key;
            savedSettings.pubnub_subscribe_key = res.data.pubnub_subscribe_key;

            localStorage.setItem('settings', JSON.stringify(savedSettings));
            dispatch({
                type: SAVE_SETTINGS,
                payload: savedSettings
            });
            callbackSuccess(true);
        }).catch(err => {
            callbackSuccess(false, err.response.status);
        });
    } else if(settings.preparation_time) {
        try{
            const savedSettings = JSON.parse(localStorage.getItem('settings')) || {};
            savedSettings.preparation_time = settings.preparation_time;

            localStorage.setItem('settings', JSON.stringify(savedSettings));
            dispatch({
                type: SAVE_SETTINGS,
                payload: savedSettings
            });
            callbackSuccess(true);
        } catch(err){
            callbackSuccess(false);
        }
    }
    
};

export const viewItem = (id, now) => (dispatch) => {
    axios
        .put(`/api/items/${id}`, {
            read: now,
            last_jingle_pause: now
        })
        .catch(err => console.log(err));
};

export const setLastJinglePause = (id, pauseDate) => dispatch => {
    axios
        .put(`/api/items/${id}`, {
            last_jingle_pause: pauseDate 
        })
        .catch(err => console.log(err));
};

export const setItemsLoading = () => {
    return {
        type: ITEMS_LOADING
    };
};
