import React, { Component } from 'react';
import {
    Container,
    Row,
    Col
} from 'reactstrap';
import { connect } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Moment from 'react-moment';

class OrderDetails extends Component {
    render(){
        const { item } = this.props;
        const order = item.order;
        const delivery_task = order.delivery_tasks[0] || {};
        const extra_charges = order.order_extra_charges;
        const menu_items = order.order_menu_items;
        
        return (
            <Row>
                <Container style={{paddingBottom: '1rem'}}>
                        <Row style={{fontWeight: 'bold'}}>
                            <Col sm="12">Customer Details</Col>
                        </Row>
                        <Row>
                            <Col sm="3">Name:</Col>
                            <Col>{[order.first_name, order.last_name].join(" ")}</Col>
                        </Row>
                        <Row>
                            <Col sm="3">Phone:</Col>
                            <Col>{order.phone_number ? 
                                parsePhoneNumberFromString(order.phone_number)
                                .formatInternational() : "None"}</Col>
                        </Row>
                        <Row>
                            <Col sm="3">Alternate Phone:</Col>
                            <Col>{order.phone_number_2 ? 
                                parsePhoneNumberFromString(order.phone_number_2)
                                .formatInternational() : "None"}</Col>
                        </Row>
                        <Row>
                            <Col sm="3">Delivery Instructions:</Col>
                            <Col>{order.delivery_instructions || "None"}</Col>
                        </Row>
                        {delivery_task.delivery_time_to ? 
                        <>
                            <Row>
                                <Col sm="3" style={{ color: "red"}}>Pre-order for:</Col>
                                <Col style={{ color: "red"}}>
                                    <Moment 
                                        format={delivery_task.pre_order_time==='range' ? "dddd, MMMM Do" : "dddd, MMMM Do, h:mma"} 
                                        date={new Date(delivery_task.delivery_time_to)} local />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="3" style={{ color: "red"}}>Pickup or Delivery?</Col>
                                <Col style={{ color: "red"}}>
                                    {delivery_task.method === "pickup" ? "Pickup" : "Delivery"}
                                </Col>
                            </Row>
                            {
                                delivery_task.method === "pickup" ?
                                <Row>
                                    <Col sm="3" style={{ color: "red"}}>Payment</Col>
                                    <Col style={{ color: "red"}}>
                                        {order.payment_type===1 ? "At the merchant" : "Card, prepaid online"}
                                    </Col>
                                </Row>:""
                            }
                        </>
                        :
                        ''
                        }
                </Container>
                <Container style={{paddingBottom: '1rem'}}>     
                 
                    <Row style={{fontWeight: 'bold'}}>
                            <Col sm="12">Order Details (ID: {order.id})</Col>
                    </Row>   
                    {menu_items.map((menu_item, index) => (
                    <Container style={{paddingBottom: '1rem', paddingLeft: '0'}} key={index}>
                        <Row style={{fontWeight: 'bold'}}>
                            <Col sm="10">
                                <span>{menu_item.quantity} x </span>
                                <span style={{textDecoration: 'underline'}}>{menu_item.name}</span>
                            </Col>
                            <Col>${menu_item.total}</Col>
                        </Row>
                        {menu_item.options_descriptions.map((option, index) => (
                        <Row key={index}>
                            <Col>{option.key}: {option.value}</Col>
                        </Row>
                        ))}
                        {menu_item.special_instructions ? 
                        <>
                            <Row style={{paddingTop: '1rem', fontWeight: 'bold'}}>
                                <Col>SPECIAL REQUESTS</Col>
                            </Row>
                            <Row> 
                                <Col>{menu_item.special_instructions}</Col>
                            </Row>
                        </>
                        :
                        ''
                        }
                    </Container> 
                    ))}
                    <Container style={{paddingBottom: '1rem', paddingLeft: '0'}}> 
                        <Row>
                            <Col sm="10">{extra_charges.filter(charge => !charge.name.includes("Hopscotch")).length > 0 ? 
                                "Food subtotal" : "Food total"}</Col>
                            <Col>${delivery_task.original_food_cost}</Col>
                        </Row>
                        {extra_charges.filter(charge => !charge.name.includes("Hopscotch")).map((extra_charge, index) => (
                            <Row key={index}>
                                <Col sm="10">{extra_charge.name}</Col>
                                <Col>${Number(extra_charge.original_fee).toFixed(2)}</Col>
                            </Row>
                        ))}
                        {extra_charges.filter(charge => !charge.name.includes("Hopscotch")).length > 0 ? (
                            <Row>
                                <Col sm="10">Food total</Col>
                                <Col>$
                                    {(
                                        Number(delivery_task.original_food_cost) + 
                                        extra_charges.reduce((prevChargeTotal, charge) =>
                                        prevChargeTotal + 
                                        (!charge.name.includes("Hopscotch") ?
                                            Number(charge.original_fee) : 0)
                                        , 0)
                                    ).toFixed(2)  
                                    }
                                </Col>
                            </Row>
                        ) : ''}
                    </Container>
                </Container>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    globalState: state.item
});
export default connect(mapStateToProps, { })(OrderDetails);