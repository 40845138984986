module.exports = {
    ORDER_STATUS_BEING_AMENDED: 0,
    ORDER_STATUS_PENDING: 1,
    ORDER_STATUS_IN_PROGRESS: 2,
    ORDER_STATUS_REJECTED: 3,
    ORDER_STATUS_SUCCESSFUL: 4,
    ORDER_STATUS_CANCELLED: 5,
    
    TASK_STATUS_STARTED: 1,
    TASK_STATUS_SUCCESSFUL: 2,
    TASK_STATUS_ARRIVED: 4,
    TASK_STATUS_CANCELLED: 9,

    CONFIRMATION_TIME_IN_SECONDS: 180
}