//Random change for deployment3
import React, { Component } from 'react';
import SettingsModal from './SettingsModal';
import { connect } from 'react-redux';
import logo from '../static/hopscotch_logo2.png';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    Container
} from 'reactstrap';

import { 
    getItems, 
    addItems,
    removeItem,
    updateItems 
}  from '../actions/itemActions';
import PubNubReact from 'pubnub-react';

class AppNavbar extends Component {
    constructor(props) {
        super(props);
        this.initializePubNub();
        this.props.getItems(this.callback);
    }

    pubnubListener = { 
        message: payload => {  
            console.log(`Received: ${JSON.stringify(payload.message)}`);
            if (payload.message.refresh){

                if(payload.message.new_items || payload.message.updated_items){
                    if(payload.message.new_items){
                        this.props.addItems(payload.message.new_items);
                    } 
                    
                    
                    if (payload.message.updated_items){
                        // If Hopscotch sends a message on an item that isn't showing (eg, it's from yesterday and complete), 
                        // then add it 
                        if(payload.message.updated_items.length===1 && 
                            !this.props.globalState.items.find(item => item._id===payload.message.updated_items[0]._id) && 
                            payload.message.updated_items[0].messages.some(m => m.sender === "Hopscotch" && !m.readByRestaurant)
                            ){
                            this.props.addItems(payload.message.updated_items);
                        } else {
                            this.props.updateItems(payload.message.updated_items);
                        }
                    }

                    if (payload.message.removed_item_id){
                        this.props.removeItem(payload.message.removed_item_id);
                    }
                } else {
                    this.props.getItems();
                }
                
            }
        } 
    }

    componentDidUpdate(prevProps){
        const settings = this.props.globalState.settings;
        if(prevProps.globalState.settings.restaurant_id !== settings.restaurant_id){
            this.initializePubNub();
            this.props.getItems(); 
        }      
    }

    componentWillUnmount() {
        if(this.pubnub){
            this.pubnub.removeListener(this.pubnubListener);
            this.pubnub.unsubscribeAll();
        } 
        
    }

    initializePubNub = () => {
        const settings = this.props.globalState.settings;
        if(settings.pubnub_publish_key && settings.pubnub_subscribe_key && settings.restaurant_id){
            if(!this.pubnub){
                this.pubnub = new PubNubReact({ 
                    publishKey: settings.pubnub_publish_key, 
                    subscribeKey: settings.pubnub_subscribe_key
                });
                this.pubnub.init(this);
                this.pubnub.addListener(this.pubnubListener); 
            }
    
            this.pubnub.unsubscribeAll();
            this.pubnub.subscribe({ 
                channels: [settings.restaurant_id] 
            });
        }
    }



    state = {
        isOpen: false
    }
    
    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render(){
        const { settings } = this.props.globalState;
        return (
                <Navbar sticky="top" style={{backgroundColor: '#a32273'}} dark expand="sm" className="mb-5">
                    <Container>
                        <div style={{width: '25%', display: 'inline'}}>
                            <img style={{maxWidth: '100%', maxHeight: '100%'}} src={logo} alt="Hopscotch"/>
                        </div>
                        <span style={{color: 'white', width: '100%', textAlign: 'center'}}>
                            {settings.restaurant_name ? settings.restaurant_name[0] 
                            : "[Restaurant not configured]"}
                        </span>
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="ml-auto" navbar>
                                <NavItem>
                                    <SettingsModal />
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
        );
    }
}

const mapStateToProps = (state) => ({
    globalState: state.item
});

export default connect(mapStateToProps, { 
    getItems, 
    removeItem,
    addItems,
    updateItems 
} )(AppNavbar);