import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody
} from 'reactstrap';
import ConfirmOrderModal from './ConfirmOrderModal';
import OrderProblemModal from './OrderProblemModal';
import CompletePickupModal from './CompletePickupModal';
import Messages from './Messages';
import OrderDetails from './OrderDetails';
import { connect } from 'react-redux';

class ItemDetails extends Component {  
    render(){
        const { item } = this.props;
        const isPickup = (item && item.order && item.order.delivery_tasks[0] && item.order.delivery_tasks[0].method==="pickup");
        return (
            <Card style={{border: 'none'}} sm="6">
                <CardBody style={{padding: '0'}}> 
                    <Container style={{maxWidth: '100%', paddingRight: '0'}}>
                        <Row>
                            <Col sm={12} md={7}>  
                                <OrderDetails item={item} />
                                <Container style={{maxWidth: '100%', padding: '0'}}>
                                    <Row style={{marginBottom: '1em'}}> 
                                        <Col sm={{size: 3, offset: isPickup?2:3}}>
                                            <ConfirmOrderModal item={item} />
                                        </Col>
                                        <Col sm={{size: 3}}>
                                            <OrderProblemModal item={item} />
                                        </Col>
                                        {
                                            isPickup ? 
                                            <Col sm={{size: 3}}>
                                                <CompletePickupModal item={item} />
                                            </Col> :
                                            ''
                                        }
                                    </Row>
                                </Container>
                            
                            </Col>
                            <Col>
                                <Messages item={item} />
                            </Col>
                        </Row>
                    </Container>
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    globalState: state.item
});
export default connect(mapStateToProps, { })(ItemDetails);