import React from 'react';
import AppNavbar from './components/AppNavbar';
import ItemList from './components/ItemList';
import { NotificationContainer } from 'react-notifications';

import { Provider } from 'react-redux';
import store from './store';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './App.css';

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <AppNavbar />
        <ItemList />
        <NotificationContainer/>
      </div>
    </Provider>
  );
}

export default App;
