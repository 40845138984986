import React, { Component } from 'react';
import Button from 'reactstrap-button-loader';
import { NotificationManager } from 'react-notifications';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Input
} from 'reactstrap';

import { connect } from 'react-redux';
import { markProblemOrder } from '../actions/itemActions';

const ORDER_STATUS_PENDING = require('./constants').ORDER_STATUS_PENDING;

class OrderProblemModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: false,
            message: ""
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
            message: ""
        });
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    callback = success  => {
        this.setState({ loading: false });
        this.toggle();
        if(success){
            NotificationManager.success('Thanks for telling us! We\'ll will get back to you soon.');
        } else {
            NotificationManager.error('There was a problem updating the order.');
        }
    }

    onSubmit = e => {
        e.preventDefault();
        
        let problemObj = {}; 
        problemObj.item_id = this.props.item._id;
        problemObj.problem_date = new Date();
        if(this.state.message){
            problemObj.message = {
                text: this.state.message,
                isProblem: true,
                sent: new Date(),
                sender: this.props.globalState.settings.restaurant_name,
                recipient: "Hopscotch"
            };
        }

        this.props.markProblemOrder(problemObj, this.callback);
        this.setState({ loading: true });
    }

    render(){
        const { item } = this.props;
        return (
            <>
                <Button color="danger" style={{width: '100%'}}
                        disabled={
                                    !!item.deleted || 
                                    !!item.problem || 
                                    !!item.accepted || 
                                    !!item.cancelled || 
                                    !!item.cancelled_after_collection ||
                                    !!item.rejected ||
                                    item.order.state !== ORDER_STATUS_PENDING
                                }
                        onClick={this.toggle}>
                            Problem
                </Button>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader toggle={this.toggle}>Problem with the order</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>
                                                                
                                <Input 
                                    type="textarea" 
                                    name="message" 
                                    id="message" 
                                    placeholder="What's wrong with the order?"
                                    value={this.state.message}
                                    onChange={this.onChange}
                                    required
                                />

                                <Button 
                                    loading={this.state.loading} 
                                    disabled={
                                        !this.state.message ||
                                        !!item.deleted || 
                                        !!item.problem || 
                                        !!item.accepted || 
                                        !!item.cancelled || 
                                        !!item.cancelled_after_collection ||
                                        !!item.rejected ||
                                        item.order.state !== ORDER_STATUS_PENDING
                                    } 
                                    className="btn-hopscotch" 
                                    style={{ marginTop: '2rem'}} 
                                    block
                                >
                                    Report issue
                                </Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    globalState: state.item
});
export default connect(mapStateToProps, { markProblemOrder })(OrderProblemModal);