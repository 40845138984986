import React, { Component } from 'react';
import Button from 'reactstrap-button-loader';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    NavLink
} from 'reactstrap';

import { connect } from 'react-redux';
import { saveSettings } from '../actions/itemActions';
import { NotificationManager } from 'react-notifications';

class SettingsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            restaurant_id: "",
            preparation_time: localStorage.getItem('settings') ? 
                    JSON.parse(localStorage.getItem('settings')).preparation_time : '15',
            loading: false
        }
    }

    callback = (success, failureStatusCode) => {
        this.setState({ loading: false });
        this.toggle();
       
        if(success){
            NotificationManager.success('Settings successfully updated.');
        } else {
            NotificationManager.error(failureStatusCode ? 
                'The restaurant identifier you entered is invalid.': 
                'Error updating settings.');
        }
        
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
            // Use the settings in the application state, so that if the user closes the model without
            // updating the settings, the values don't change
            //restaurant_id: this.props.globalState.settings.restaurant_id,
            //preparation_time: this.props.globalState.settings.preparation_time 
        });
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit = e => {
        e.preventDefault();
        
        const settings = {
            restaurant_id: this.state.restaurant_id,
            preparation_time: this.state.preparation_time
        }

        // Save restaurant settings 
        this.setState({loading: true}); 
        this.props.saveSettings(settings, this.callback);
    }

    render(){
        return (
            <div>
                <NavLink style={{color: 'white', cursor: 'pointer'}} onClick={this.toggle}>Settings</NavLink>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader toggle={this.toggle}>Restaurant Settings</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>
                                <Label for="restaurant_id">Restaurant Identifier</Label>
                                <Input 
                                    type="text"
                                    name="restaurant_id"
                                    id="restaurant_id"
                                    placeholder="Enter restaurant identifier"
                                    style={{marginBottom: '1rem'}}
                                    value={this.state.restaurant_id}
                                    onChange={this.onChange}
                                />

                                <Label for="preparation_time">Average Preparation Time</Label>
                                <Input 
                                    type="select" 
                                    name="preparation_time" 
                                    id="preparation_time"
                                    value={this.state.preparation_time}
                                    onChange={this.onChange}
                                >
                                    <option value="5">5 minutes</option>
                                    <option value="10">10 minutes</option>
                                    <option value="15">15 minutes</option>
                                    <option value="20">20 minutes</option>
                                    <option value="25">25 minutes</option>
                                    <option value="30">30 minutes</option>
                                    <option value="45">45 minutes</option>
                                    <option value="60">1 hour</option>
                                </Input>
                                <Button 
                                    loading={this.state.loading} 
                                    className="btn-hopscotch" 
                                    style={{ marginTop: '2rem'}} 
                                    disabled={!this.state.preparation_time}
                                    block>
                                    Update settings
                                </Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    globalState: state.item
});
export default connect(mapStateToProps, { saveSettings })(SettingsModal);